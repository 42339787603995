import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { ComponentPageNav } from '~/src/components/component-page-nav';
import StatusMenu from '~/src/components/status-menu';
import { AccessibilityLabel, StatusLabel } from '@primer/gatsby-theme-doctocat';
import { Label } from '@primer/react';
import { graphql } from "gatsby";
export const query = graphql`
  query {
    primerReactVersion {
      version
    }
    allReactComponent(filter: {componentId: {eq: "action_list"}}) {
      nodes {
        status
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Box = makeShortcode("Box");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Box style={{
      marginBottom: "24px"
    }} mdxType="Box">
  <ComponentPageNav basePath="/components/action-list" includeReact={true} includeRails={true} includeFigma={true} current="react" mdxType="ComponentPageNav" />
    </Box>
    <Box style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "25px"
    }} mdxType="Box">
  <Box style={{
        display: "flex",
        gap: "5px"
      }} mdxType="Box">
    <Label size="large" mdxType="Label">@primer/react@v{props.data.primerReactVersion.version}</Label>
    <StatusLabel status="Deprecated" mdxType="StatusLabel" />
    <AccessibilityLabel a11yReviewed={false} short={false} mdxType="AccessibilityLabel" />
  </Box>
  <Box mdxType="Box">
    <StatusMenu currentStatus="deprecated" statuses={[...props.data.allReactComponent.nodes.map(reactComponent => reactComponent.status), "deprecated"]} parentPath="/components/action-list/react" mdxType="StatusMenu" />
  </Box>
    </Box>
    <p>{`An `}<inlineCode parentName="p">{`ActionList`}</inlineCode>{` is a list of items which can be activated or selected. `}<inlineCode parentName="p">{`ActionList`}</inlineCode>{` is the base component for many of our menu-type components, including and `}<inlineCode parentName="p">{`ActionMenu`}</inlineCode>{`.`}</p>
    <h2>{`Deprecation`}</h2>
    <p>{`Use the `}<a parentName="p" {...{
        "href": "/components/action-list/react"
      }}>{`new version of ActionList`}</a>{` with composable API, design updates and accessibility fixes.`}</p>
    <h3>{`Before`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ActionList
  items={[
    {text: 'New file'},
    {text: 'Copy link'},
    {text: 'Edit file'},
    ActionList.Divider,
    {text: 'Delete file', variant: 'danger'},
  ]}
/>
`}</code></pre>
    <h3>{`After`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ActionList>
  <ActionList.Item>New file</ActionList.Item>
  <ActionList.Item>Copy link</ActionList.Item>
  <ActionList.Item>Edit file</ActionList.Item>
  <ActionList.Divider />
  <ActionList.Item variant="danger">Delete file</ActionList.Item>
</ActionList>
`}</code></pre>
    <p>{`Or continue using deprecated API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {ActionList} from '@primer/react/deprecated'
`}</code></pre>
    <h2>{`Minimal example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live deprecated",
        "live": true,
        "deprecated": true
      }}>{`<ActionList
  items={[
    {text: 'New file'},
    ActionList.Divider,
    {text: 'Copy link'},
    {text: 'Edit file'},
    {text: 'Delete file', variant: 'danger'},
  ]}
/>
`}</code></pre>
    <h2>{`Example with grouped items`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live deprecated",
        "live": true,
        "deprecated": true
      }}>{`<ActionList
  groupMetadata={[
    {groupId: '0'},
    {groupId: '1', header: {title: 'Live query', variant: 'subtle'}},
    {groupId: '2', header: {title: 'Layout', variant: 'subtle'}},
    {groupId: '3'},
    {groupId: '4'},
  ]}
  items={[
    {key: '1', leadingVisual: TypographyIcon, text: 'Rename', groupId: '0', trailingVisual: '⌘R'},
    {key: '2', leadingVisual: VersionsIcon, text: 'Duplicate', groupId: '0'},
    {key: '3', leadingVisual: SearchIcon, text: 'repo:github/github', groupId: '1'},
    {
      key: '4',
      leadingVisual: NoteIcon,
      text: 'Table',
      description: 'Information-dense table optimized for operations across teams',
      descriptionVariant: 'block',
      groupId: '2',
    },
    {
      key: '5',
      leadingVisual: ProjectIcon,
      text: 'Board',
      description: 'Kanban-style board focused on visual states',
      descriptionVariant: 'block',
      groupId: '2',
    },
    {
      key: '6',
      leadingVisual: FilterIcon,
      text: 'Save sort and filters to current view',
      disabled: true,
      groupId: '3',
    },
    {key: '7', leadingVisual: FilterIcon, text: 'Save sort and filters to new view', groupId: '3'},
    {key: '8', leadingVisual: GearIcon, text: 'View settings', groupId: '4'},
  ]}
/>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      